@import 'src/utils/utils';

.article {
  @include zero;
  background-color: $Main-2;
  margin-bottom: 20px;
  padding-right: 16px;
  padding-left: 16px;

  @media(min-width: $md) {
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
  }

  & h2:first-child,
  & h3:first-child,
  & h4:first-child,
  & h5:first-child,
  & h6:first-child,
  & p:first-child,
  & ul:first-child,
  & ol:first-child,
  & a:first-child,
  & iframe:first-child
  & .video:first-child,
  & .current:first-child {
    margin-top: 0;
  }

  & h2:last-child,
  & h3:last-child,
  & h4:last-child,
  & h5:last-child,
  & h6:last-child,
  & p:last-child,
  & ul:last-child,
  & ol:last-child,
  & a:last-child,
  & iframe:last-child,
  & .video:last-child,
  & .current:last-child {
    margin-bottom: 0;
  }

  .current {
    margin-right: -16px;
    margin-left: -16px;

    @media(min-width: $md) {
      margin-right: 0;
      margin-left: 0;
    }

    &:hover {
      @media(min-width: $lg) {
        box-shadow: none;
      }
    }
  }

  &__content {
    padding-bottom: 16px;

    @media(min-width: $md) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @media(min-width: $xxl) {
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 40px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    h2 {
      @include zero;
      @include Head-25-bold;
      margin-top: 25px;
      margin-bottom: 25px;
      color: $Main-1;

      @media(min-width: $xxl) {
        @include Head-45-bold;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }

    h3 {
      @include zero;
      @include Head-22-bold;
      margin-top: 15px;
      margin-bottom: 15px;
      color: $Main-1;

      @media(min-width: $xxl) {
        @include Head-35-bold;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    h4 {
      @include zero;
      @include Text-18-bold;
      margin-top: 15px;
      margin-bottom: 15px;
      color: $Main-1;

      @media(min-width: $lg) {
        margin-top: 50px;
      }
    }

    p {
      @include zero;
      @include Text-14-reg;
      color: $Main-1;
      margin-top: 10px;
      margin-bottom: 10px;

      @media(min-width: $xl) {
        @include Text-16-reg;
      }
    }

    strong {
      font-weight: 700;
    }

    a {
      @include Text-14-reg;
      position: relative;
      display: inline-flex;
      max-width: 100%;
      padding-left: 22px;
      text-decoration: none;
      color: $Corporate-2;
      text-overflow: ellipsis;
      overflow: hidden;

      @media(min-width: $lg) {
        padding-left: 28px;
      }
      @media(min-width: $xl) {
        @include Text-16-reg;
      } 

      &:hover {
        @media(min-width: $lg) {
          text-decoration: underline;
        }
      }

      &:before {
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/images/link.svg');
        background-size: contain;
        background-position: center;
        content: '';

        @media(min-width: $lg) {
          top: 2px;
          width: 24px;
          height: 24px;
        }
      }
    }

    .image {
      margin-top: 15px;
      margin-bottom: 15px;
      border-radius: 15px;
      overflow: hidden;

      @media(min-width: $xxl) {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .table,
    .table-two,
    .table-three {

      p,
      li {
        font: inherit;
      }

      ol,
      ul {
        margin: 0;
        padding-left: 23px;

        li {
          padding-left: 5px;
          color: $Main-1;

          &:not(:first-child) {
            margin-top: 15px;
          }

          &::marker {
            font-weight: bold;
            color: $Main-1;
          }
        }
      }
    }

    .table {
      width: 100%;
      margin-right: -15px;
      margin-left: -15px;
      overflow: auto;

      @media(min-width: $lg) {
        margin: 0;
        padding: 0;
      }

      table {
        width: 768px;
        border-collapse: collapse;
        margin-top: 15px;
        margin-bottom: 15px;

        @media(min-width: $lg) {
          width: 100%;
        }

        thead {
          background-color: $Main-4;

          td {
            border-top: 12px solid transparent;
            padding-right: 15px;
            border-bottom: 12px solid transparent;
            padding-left: 15px;
            font-family: $verdana;
            font-size: 0.688rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1rem;
            letter-spacing: 0.2px;
            color: $Main-1;

            &:not(:first-of-type) {
              border-left: 1px solid $Main-6;
            }

            &:last-of-type {
              min-width: 160px;
            }


            &:nth-child(3) {
              min-width: 160px;
            }
          }
        }

        tbody {

          tr {
            &:nth-child(even) {
              background-color: $Main-3;
            }
          }

          td {
            @include Text-14-reg;
            padding: 15px;
            color: $Main-1;
            vertical-align: top;
          }
        }
      }
    }

    .table-two {
      margin-right: -15px;
      margin-left: -15px;
      overflow: auto;

      @media(min-width: $lg) {
        margin: 0;
        padding: 0;
      }

      table {
        width: 768px;
        margin-top: 15px;
        margin-bottom: 15px;
        border-collapse: collapse;
        border: 1px solid $Main-5;

        @media(min-width: $lg) {
          width: 100%;
        }

        thead {

          td {
            padding-top: 12px;
            padding-right: 15px;
            padding-bottom: 12px;
            padding-left: 15px;
            font-family: $verdana;
            font-size: 0.688rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1rem;
            letter-spacing: 0.2px;
            color: $Main-1;
            border-bottom: 1px solid $Main-5;

            &:not(:first-of-type) {
              border-left: 1px solid $Main-5;
            }

            &:last-of-type {
              min-width: 160px;
            }


            &:nth-child(3) {
              min-width: 160px;
            }
          }
        }

        tbody {

          td {
            @include Text-14-reg;
            padding: 15px;
            color: $Main-1;
            vertical-align: top;

            &:nth-child(even) {
              background-color: $Main-3;
            }
          }
        }
      }
    }

    .table-three {
      margin-right: -15px;
      margin-left: -15px;
      overflow: auto;

      @media(min-width: $lg) {
        margin: 0;
        padding: 0;
      }

      table {
        width: 768px;
        margin-top: 15px;
        margin-bottom: 15px;
        border-collapse: collapse;
        border: 1px solid $Main-5;

        @media(min-width: $lg) {
          width: 100%;
        }

        thead {

          tr {
            background-color: $Main-4;
          }

          td {
            padding-top: 12px;
            padding-right: 15px;
            padding-bottom: 12px;
            padding-left: 15px;
            font-family: $verdana;
            font-size: 0.688rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1rem;
            letter-spacing: 0.2px;
            color: $t-60;
            border-bottom: 1px solid $Main-5;

            &:not(:first-of-type) {
              border-left: 1px solid $Main-5;
            }

            // &:last-of-type {
            //   min-width: 160px;
            // }


            // &:nth-child(3) {
            //   min-width: 160px;
            // }
          }
        }

        tbody {

          tr {
            &:nth-child(even) {
              background-color: $Main-3;
            }
          }

          td {
            @include Text-14-reg;
            padding: 15px;
            color: $Main-1;
            vertical-align: top;

            &:not(:first-of-type) {
              border-left: 1px solid $Main-5;
            }
          }
        }
      }
    }

    .link {
      @include Text-16-reg;
      border: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      padding: 10px;
      border-radius: 10px;
      background-color: $Corporate-2;
      color: $Main-2;
      transition: 0.3s;
      cursor: pointer;
      text-decoration: none;

      @media(min-width: $lg) {
        margin-top: 30px;
        margin-bottom: 30px;
        padding-top: 15px;
        padding-right: 31px;
        padding-bottom: 15px;
        padding-left: 31px;
      }

      &:before {
        display: none;
      }

      svg {
        min-width: 24px;
        min-height: 24px;
        width: 24px;
        height: 24px;
        stroke: $Main-2;
      }

      &:hover {
        @media(min-width: $lg) {
          background-color: $Corporate-1;
          text-decoration: none;
        }
      }

      &--red {
        background-color: $Corporate-1;

        &:hover {
          @media(min-width: $lg) {
            background-color: $Corporate-3;
          }
        }
      }

      &--gray {
        background-color: $Main-4;
        color: $Main-6;

        &:hover {
          @media(min-width: $lg) {
            background-color: $Main-5;
          }
        }

        svg {
          stroke: $Main-6;
        }
      }

      &--full {
        display: flex;
      }

      &--disabled {
        pointer-events: none;
        background-color: $Main-6;
        color: $Dark-1;

        svg {
          stroke: $Dark-1;
        }
      }
    }

    .black {
      @include zero;
      list-style: none;
      counter-reset: index;

      li {
        @include zero;
        @include Text-14-reg;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 30px;
        color: $Main-1;

        @media(min-width: $lg) {
          @include Text-16-reg;
          padding-left: 40px;
        }

        &:before {
          @include Text-14-bold;
          position: absolute;
          top: 0;
          left: 0;
          counter-increment: index;
          color: $Main-1;
          content: counter(index)".";

          @media(min-width: $lg) {
            @include Text-16-bold;
          }
        }

        ol {
          @include zero;
          list-style: none;
          counter-reset: inner;

          li {
            @include zero;
            @include Text-14-reg;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-left: 35px;
            color: $Main-1;

            @media(min-width: $lg) {
              @include Text-16-reg;
              padding-left: 45px;
            }
  
            &:before {
              @include Text-14-bold;
              position: absolute;
              top: 0;
              left: 0;
              counter-increment: inner;
              color: $Main-1;
              content: counter(index)'.'counter(inner)".";

              @media(min-width: $lg) {
                @include Text-16-bold;
              }
            }
          }
        }
      }
    }

    .red {
      @include zero;
      list-style: none;
      counter-reset: index;

      li {
        @include zero;
        @include Text-14-reg;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 30px;
        color: $Main-1;

        @media(min-width: $lg) {
          @include Text-16-reg;
          padding-left: 40px;
        }

        &:before {
          @include Text-14-bold;
          position: absolute;
          top: 0;
          left: 0;
          counter-increment: index;
          color: $Corporate-2;
          content: counter(index)".";

          @media(min-width: $lg) {
            @include Text-16-bold;
          }
        }

        ol {
          @include zero;
          list-style: none;
          counter-reset: inner;

          li {
            @include zero;
            @include Text-14-reg;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-left: 35px;
            color: $Main-1;

            @media(min-width: $lg) {
              @include Text-16-reg;
              padding-left: 45px;
            }
  
            &:before {
              @include Text-14-bold;
              position: absolute;
              top: 0;
              left: 0;
              counter-increment: inner;
              color: $Corporate-2;
              content: counter(index)'.'counter(inner)".";

              @media(min-width: $lg) {
                @include Text-16-bold;
              }
            }
          }
        }
      }
    }

    .black-empty {
      @include zero;
      list-style: none;

      ul {
        @include zero;
        list-style: none;
      }

      li {
        @include zero;
        @include Text-14-reg;
        position: relative;
        padding-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $Main-1;

        @media(min-width: $lg) {
          @include Text-16-reg;
        }

        &:before {
          position: absolute;
          top: 6px;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid $Main-1;
          content: '';

          @media(min-width: $lg) {
            top: 8px;
          }
        }

      }
    }

    .black-full {
      @include zero;
      list-style: none;

      ul {
        @include zero;
        list-style: none;
      }

      li {
        @include zero;
        @include Text-14-reg;
        position: relative;
        padding-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $Main-1;

        @media(min-width: $lg) {
          @include Text-16-reg;
        }

        &:before {
          position: absolute;
          top: 6px;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $Main-1;
          content: '';

          @media(min-width: $lg) {
            top: 8px;
          }
        }
      }
    }

    .red-empty {
      @include zero;
      list-style: none;

      ul {
        @include zero;
        list-style: none;
      }

      li {
        @include zero;
        @include Text-14-reg;
        position: relative;
        padding-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $Main-1;

        @media(min-width: $lg) {
          @include Text-16-reg;
        }

        &:before {
          position: absolute;
          top: 6px;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 2px solid $Corporate-2;
          content: '';

          @media(min-width: $lg) {
            top: 8px;
          }

        }

      }
    }

    .red-full {
      @include zero;
      list-style: none;

      ul {
        @include zero;
        list-style: none;
      }

      li {
        @include zero;
        @include Text-14-reg;
        position: relative;
        padding-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $Main-1;

        @media(min-width: $lg) {
          @include Text-16-reg;
        }

        &:before {
          position: absolute;
          top: 6px;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $Corporate-2;
          content: '';

          @media(min-width: $lg) {
            top: 8px;
          }
        }

      }
    }

    .action {
      @include zero;
      margin-top: 25px;
      margin-bottom: 25px;

      @media(min-width: $xl) {
        display: grid;
        align-items: start;
        grid-template-columns: 1fr 200px;
        column-gap: 40px;
        margin-top: 50px;
        margin-bottom: 50px;
      }

      p {
        margin: 0;
      }

      .link {
        @media(min-width: $xl) {
          margin: 0;
        }
      }
    }

    .steps {
      @include zero;
      margin-top: 25px;
      margin-bottom: 25px;
      list-style: none;
      counter-reset: step;

      li {
        @include zero;
        position: relative;
        counter-increment: step;
        padding-left: 30px;

        @media(min-width: $xxl) {
          padding-left: 70px;
        }

        &:after {
          @include Text-18-bold;
          line-height: 1.438rem;
          position: absolute;
          top: 0;
          left: 0;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: $Corporate-2;
          content: counter(step);
          text-align: center;
          color: $Main-2;

          @media(min-width: $xxl) {
            @include Text-18-bold;
            width: 50px;
            height: 50px;
            line-height: 2.875rem;
          }
        }

        &:not(:last-of-type) {
          padding-bottom: 20px;

          @media(min-width: $xl) {
            padding-bottom: 40px;
          }

          &:before {
            position: absolute;
            top: 0;
            left: 12px;
            width: 1px;
            height: 100%;
            background-color: $Corporate-2;
            content: '';

            @media(min-width: $xxl) {
              left: 24px;
            }
          }
        }
      }

      .step-counter {
        @include zero;
        @include Text-18-bold;
        color: $Corporate-2;
        margin-bottom: 10px;

        @media(min-width: $md) {
          margin-bottom: 15px;
        }
      }

      .step-header {
        @include zero;
        @include Text-16-bold;
        color: $Main-1;
        margin-bottom: 10px;
      }

      .step-text {
        @include zero;
        @include Text-16-reg;
        color: $Main-1;
      }

    }

    .file {
      @include zero;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      column-gap: 10px;
      background-color: $Main-3;
      align-items: center;
      transition: 0.3s;
      border-radius: 10px;

      @media(min-width: $xl) {
        padding-right: 15px;
        padding-left: 15px;
      }

      svg {
        // display: block;
        // width: 26px;
        // height: 36px;
      }

      p {
        @include zero;
        @include Text-14-reg;
        color: $Main-1;
        margin-bottom: 6px;
      }

      span {
        @include zero;
        @include Text-12-reg;
        color: $t-60;
      }
      
      i {
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $verdana;
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1rem;
        text-transform: uppercase;
        text-align: center;
      }

      section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5px;
        margin-bottom: auto;
      }

      &:before {
        display: none;
      }

      &:hover {
        @media(min-width: $lg) {
          background-color: $Main-4;
          text-decoration: none;
        }
      }
    }

    .video,
    iframe {
      @include zero;
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      border-radius: 10px;
  
      @media(min-width: 425px) {
        height: 250px;
      }
      @media(min-width: $md) {
        border-radius: 20px;
      }
      @media(min-width: $xl) {
        height: 540px;
      }
      @media(min-width: $xxl) {
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }

    .in-cut {
      @include zero;
      margin-top: 20px;
      margin-right: -16px;
      margin-bottom: 20px;
      margin-left: -16px;
      padding: 15px;
      // background-color: $Alternative-9;

      @media(min-width: $md) {
        margin-right: unset;
        margin-left: unset;
        border-radius: 20px;
      }
      @media(min-width: $xxl) {
        margin-top: 50px;
        margin-bottom: 50px;
      }

      .in-cut-header {
        @include zero;
        @include Text-16-bold;
        // color: $Main-1;
        color: inherit;
        margin-bottom: 10px;
      }

      p {
        @include zero;
        @include Text-14-reg;
        // color: $Main-1;
        color: inherit;

        @media(min-width: $xxl) {
          @include Text-16-reg; 
        }
      }
    }

    .double {
      @include zero;
      margin-top: 20px;
      margin-bottom: 20px;

      @media(min-width: $lg) {
        display: grid;
        column-gap: 40px;
        grid-template-columns: 1fr 1fr;
      }
      @media(min-width: $xxl) {
        margin-top: 50px;
        margin-bottom: 50px;
      }

      p {
        @media(min-width: $lg) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .direction {
      @include zero;
      display: grid;
      row-gap: 10px;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid $Main-4;

      @media(min-width: $xl) {
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        margin-top: 30px;
        padding-top: 30px;
      }

      div {
        @include zero;
        display: grid;
        row-gap: 10px;

        @media(min-width: $xl) {
          row-gap: 20px;
        }
      }

      p {
        @include zero;
        @include Text-16-bold;
        color: $Main-1;

        @media(min-width: $xl) {
          @include Text-18-bold;
        }
      }
    }


    .question {

      h2 {
        @include Text-16-bold;
        margin-bottom: 10px;
        color: $Main-1;
        
        @media(min-width: $lg) {
          @include Text-18-bold;
        }
      }

      &__gray {
        padding-left: 15px;

        @media(min-width: $lg) {
          padding-left: 30px;
        }

        h2 {
          @include zero;
          @include Text-16-bold;
          position: relative;
          color: $Corporate-2;

          @media(min-width: $lg) {
            @include Text-18-bold;
          }

          &:before {
            position: absolute;
            top: 10px;
            left: -15px;
            width: 5px;
            height: calc(100% - 20px);
            background-color: $Corporate-2;
            content: '';

            @media(min-width: $lg) {
              left: -30px;
            }
          }
        }

        p {
          color: $Dark-3;
        }
      }

      &__box {
        @media(min-width: $md) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media(min-width: $lg) {
          display: block;
        }
        @media(min-width: $xl) {
          display: flex;
          margin-top: 20px;
        }
      }

      &__source {

        span {
          color: $t-40;
        }
      }

      &__source-link {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
        margin-bottom: 10px;

        a {
          @media(min-width: $xl) {
            font-size: 0.875rem;
          }
        }
      }


    }
  }
}